import {  
  HospitalFeatures,
  PersonalFeatures,
  PractitionerFeatures,
} from "../../constants";
import { FaCheck } from "react-icons/fa";

const PricingPlans = () => {
  return (
    <div className="md:flex lg:justify-center justify-between flex-wrap lg:space-x-10 lg:mx-auto mx-[2rem]">
      <div className="xl:w-[25%] lg:w-[27%] md:w-[48%] w-full border rounded-3xl xl:p-10 p-6 mb-10 shadow-[2px_2px_10px_4px_rgba(0,_0,_0,_0.1)] bg-white hover:border-blue-400">
        <h3 className="text-darkBlue xl:text-3xl text-2xl font-semibold">
          Personal
        </h3>
        <a href="mailto:contact@grid78.com?subject=Grid%2078%20Trial">
          <button className="w-full py-3 my-6 bg-gradient-to-r from-blue-400 to-gray-300 rounded-3xl font-semibold">
            Get Started
          </button>
        </a>
        <p className="font-medium text-xl mb-4">Features:</p>
        <ul>
          {PersonalFeatures?.map((feature, index) => (
            <li className="flex gap-4 items-center mb-2" key={index}>
              <div className="w-[6%]">
                <FaCheck className="text-blue-500 w-full" />
              </div>
              <p className="w-[90%] leading-8">{feature.text}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="xl:w-[25%] lg:w-[27%] md:w-[48%] w-full border rounded-3xl xl:p-10 p-6 mb-10 shadow-[2px_2px_10px_4px_rgba(0,_0,_0,_0.1)] bg-white hover:border-blue-400">
        <div className="flex justify-between items-center">
          <h3 className="text-darkBlue xl:text-3xl text-2xl font-semibold">
            Practitioner
          </h3>
          {/* <p className="bg-green-100 font-medium text-green-500 px-4 xl:py-2 py-[4px] rounded-3xl">
            Popular
          </p> */}
        </div>
        <a href="mailto:contact@grid78.com?subject=Grid%2078%20Trial">
          <button className="w-full py-3 my-6 bg-gradient-to-r from-blue-400 to-gray-300 rounded-3xl font-semibold">
            Get Started
          </button>
        </a>
        <p className="font-medium text-xl mb-4">Features:</p>
        <ul>
          {PractitionerFeatures?.map((feature, index) => (
            <li className="flex gap-4 items-center mb-2" key={index}>
              <div className="w-[6%]">
                <FaCheck className="text-blue-500 w-full" />
              </div>
              <p className="w-[90%] leading-8">{feature.text}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="xl:w-[25%] lg:w-[27%] md:w-[48%] w-full border rounded-3xl xl:p-10 p-6 mb-10 shadow-[2px_2px_10px_4px_rgba(0,_0,_0,_0.1)] bg-white hover:border-blue-400">
        <h3 className="text-darkBlue xl:text-3xl text-2xl font-semibold">
          Hospital
        </h3>
        <a href="mailto:contact@grid78.com?subject=Grid%2078%20Trial">
          <button className="w-full py-3 my-6 bg-gradient-to-r from-blue-400 to-gray-300 rounded-3xl font-semibold">
            Get Started
          </button>
        </a>
        <p className="font-medium text-xl mb-4">Features:</p>
        <ul>
          {HospitalFeatures?.map((feature, index) => (
            <li className="flex gap-4  items-center mb-2" key={index}>
              <div className="w-[6%]">
                <FaCheck className="text-blue-500 w-full" />
              </div>
              <p className="w-[90%] leading-8">{feature.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PricingPlans;
