import React, { useState } from 'react';
import '../style.css'
const AccordionGroup = ({ children }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle open/close
  };

  return (
    <div>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          isOpen: openIndex === index, // Pass whether it's open
          onToggle: () => toggleAccordion(index), // Pass the toggle function
        })
      )}
    </div>
  );
};

export default AccordionGroup;
