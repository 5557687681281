const TermsConditions = () => {
  return (
    <div className="py-6 md:mt-10 mt-5 mb-10 lg:px-20 px-10 flex flex-col md:space-y-8 space-y-6">
      <div>
        <h2 className="text-xl font-bold mb-2">1. Acceptance of Terms</h2>
        <p className="md:text-xl">
          By accessing, using, or interacting with our services, you fully agree
          to these Terms and Conditions. If you do not accept, discontinue use
          immediately. Your continued use constitutes binding acceptance.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">2. Use of Services</h2>
        <p className="md:text-xl">
          You agree to use our services solely at your own risk. We reserve the
          right to modify, restrict, suspend, or terminate services at any time,
          without notice, and for any reason we deem appropriate. We are under
          no obligation to provide any explanation or justification for such
          actions.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2"> 3. User Responsibilities</h2>
        <p className="md:text-xl">
          You are responsible for maintaining the confidentiality of your
          account and all actions taken under your account. You are solely
          liable for any activity on your account, and we assume no
          responsibility for unauthorized access.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">4. Intellectual Property</h2>
        <p className="md:text-xl">
          All content, logos, data, and materials on this site are owned by us
          and protected under intellectual property laws. Any reproduction,
          distribution, or commercial use is strictly prohibited unless
          explicitly authorized by us in writing. Any unauthorized use may
          result in immediate legal action.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2"> 5. Disclaimer of Warranties</h2>
        <p className="md:text-xl">
          We provide our services "as is" and "as available." We make no
          warranties, express or implied, regarding the accuracy, completeness,
          or reliability of our services. We expressly disclaim any liability
          for errors, interruptions, or inaccuracies in the services.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">6. Limitation of Liability</h2>
        <p className="md:text-xl">
          To the maximum extent permitted by law, we are not liable for any
          damages, including direct, indirect, incidental, or consequential
          losses, arising out of or related to the use or inability to use our
          services. This includes, but is not limited to, loss of data, profits,
          business interruption, or any other damages, even if we have been
          advised of the possibility of such damages.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">7. Indemnification</h2>
        <p className="md:text-xl">
          You agree to indemnify, defend, and hold us harmless from any claims,
          liabilities, damages, or costs (including legal fees) arising out of
          your use of our services, violation of these Terms, or infringement of
          any rights of a third party.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2"> 8. Termination of Use</h2>
        <p className="md:text-xl">
          We reserve the right, at our sole discretion, to terminate or suspend
          your access to our services at any time, without notice or liability,
          for any reason. Upon termination, your right to use our services
          ceases immediately.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">9. Governing Law</h2>
        <p className="md:text-xl">
          These Terms and Conditions are governed by and construed in accordance
          with the laws of [Insert Jurisdiction], and you irrevocably submit to
          the exclusive jurisdiction of its courts. You agree that any dispute
          arising from these terms will be settled solely under the laws of this
          jurisdiction.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">10. Changes to Terms</h2>
        <p className="md:text-xl">
          We reserve the right to modify these Terms and Conditions at any time,
          without prior notice. It is your responsibility to regularly review
          these Terms. Continued use of our services after changes are posted
          constitutes your acceptance of those changes.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">11. Entire Agreement</h2>
        <p className="md:text-xl">
          These Terms constitute the entire agreement between you and us
          regarding the use of our services and supersede any prior agreements
          or understandings.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">12. Contact Information</h2>
        <p className="md:text-xl">
          If you have any questions or concerns regarding these Terms, please
          contact us at{" "}
          <a
            href="mailto:contact@grid78.com?subject=Grid%2078%20Trial"
            className="text-blue-600 underline"
          >
            contact@grid78.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
