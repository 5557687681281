import React from "react";
import Navbar from "../../components/Navbar";
import Header from "./Header";
import Footer from "../../components/Footer";
import HowItWorks from "./HowItWorks";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import Services from "./Services";

const Home = () => {
  return (
    <div>
      <div className="relative h-[100vh] bg-hero-image bg-cover bg-top bg-no-repeat">
        {/* Overlay */}
        <div className="absolute inset-0 bg-gray-900 bg-opacity-30"></div>

        {/* Content */}
        <div className="relative z-10 flex flex-col justify-between h-full">
          <Navbar />
          <Header />
        </div>
      </div>

      <Services />
      <HowItWorks />
      <Pricing/>
      <FAQ />
      <Footer />
    </div>
  );
};

export default Home;
