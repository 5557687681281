import { Link } from "react-router-dom";
import logo from "../../assets/images/grid78-white-logo.png";
import { FaEnvelope } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { QuickLinks } from "../../constants";
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-gray-900 lg:px-20 px-10 py-12">
      <div className="lg:flex items-start ">
        <div className="lg:w-3/5 mb-[5rem]">
          <div>
            <Link to="/">
            <img src={logo} alt="" className="w-[100px]" />
            </Link>
          </div>
        </div>
        <div className="lg:w-2/5 md:flex">
          <div className="md:w-1/2 md:mb-0 mb-[3rem]">
            <h3 className="text-2xl font-medium md:mb-10 mb-5 text-primary-blue">
              Quick links
            </h3>
            <ul>
              {QuickLinks.map((link, index) => {
                return (
                  <a href={link.route} className="" key={index}>
                    <li className="md:text-[18px] md:mb-4 mb-2 hover:text-gray-400 text-white">
                      {link.title}
                    </li>
                  </a>
                );
              })}
            </ul>
          </div>
          <div className="md:w-1/2 md:mb-0 mb-[3rem]">
            <h3 className="text-2xl font-medium md:mb-10 mb-5 text-primary-blue">
              Contact
            </h3>
            <a
              href="mailto:contact@grid78.com?subject=Grid%2078%20Trial"
              className="flex space-x-2 md:text-xl md:mb-4 mb-2 hover:text-gray-400 text-white"
            >
              <p>
                <FaEnvelope className="mt-1" />
              </p>
              <p className="break-all text-[18px]">contact@grid78.com</p>
            </a>
            <a
              href="https://maps.app.goo.gl/EUU5amq7SYiXSqoy8"
              className="flex items-start space-x-2 md:text-xl md:mb-4 mb-2 hover:text-gray-400 text-white"
            >
              <p>
                <FaLocationDot className="mt-2" />
              </p>
              <p className="leading-8 text-[18px]">
                1309 Coffeen Avenue STE 1200 Sheridan Wyoming 82801
              </p>
            </a>
          </div>
        </div>
      </div>
      <p className="line border border-b-1 border-gray-500 mt-[4rem]"></p>
      <p className="text-gray-400 mt-10">
        Copyright © {currentYear} Grid 78. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
