export const MENU = [
  {
    title: "Services",
    route: "#services",
  },
  {
    title: "How It Works",
    route: "#how-it-works",
  },
  {
    title: "Pricing",
    route: "#pricing",
  },
  {
    title: "FAQ",
    route: "#faq",
  },
];

export const QuickLinks = [
  {
    title: "Terms & Conditions",
    route: "/terms",
  },
  {
    title: "Privacy Policy",
    route: "/privacy",
  },
 
];

// export const SupportLinks = [
//   {
//     title: "Terms & Conditions",
//     route: "/terms",
//   },
//   {
//     title: "Privacy Policy",
//     route: "/privacy",
//   },
//   {
//     title: "FAQ",
//     route: "/faq",
//   },
//   {
//     title: "Support",
//     route: "mailto:commercial@crudguard.com?subject=Crudguard%20Trial",
//   },
  
// ];

export const PersonalFeatures = [
  {
    text: "Transferrable Health Records",
  },
  {
    text: "Personalized Recommendations",
  },
  {
    text: "Health Insurance",
  },
  {
    text: "Lifestyle Tracking & Management",
  },
  {
    text: "Health Analytics",
  },
];

export const PractitionerFeatures = [
  {
    text: "Multi Hospital Access",
  },
  {
    text: "Appointment Management",
  },
  {
    text: "Patient Management",
  },
  {
    text: "Notes and History Management",
  },
  {
    text: "Access Control",
  },
];

export const HospitalFeatures = [
  {
    text: "Patient Management",
  },
  {
    text: "Appointment Scheduling",
  },
  {
    text: "Notes and History Management",
  },
  {
    text: "Ward & Bed Management",
  },
  {
    text: "Consultations, Admissions & Procedures",
  },
  {
    text: "Finance Management",
  },
  {
    text: "Access Control",
  },
  {
    text: "AI Query Assistant",
  },
  {
    text: "Demographic, Hospital and Patient Analytics",
  },
  {
    text: "Ad-Hoc Customizations",
  },
];
