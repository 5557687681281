import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MdLogin, MdPersonAdd } from "react-icons/md";
import logo from "../../assets/images/grid78-white-logo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { MENU } from "../../constants";

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);

  const handleNavToggle = () => {
    setNavOpen(!navOpen);
  };

  const location = useLocation(); // Get current location

  return (
    <nav className="w-full z-10">
      <div className="flex justify-between items-center py-4 lg:mx-20 mx-10">
        {/* Logo or Brand Name */}
        <div>
          <Link to="/">
          <img src={logo} alt="" className="w-[100px]" />
          </Link>
        </div>

        {/* Hamburger Icon (mobile view) */}
        <div className="lg:hidden">
          <button onClick={handleNavToggle}>
            <FaBars size={24} className="text-white" />
          </button>
        </div>

        {/* Links (desktop view) */}
        <ul className="hidden lg:flex xl:space-x-16 lg:space-x-6 font-semibold">
          {MENU.map((link, index) => {
            return (
              <li key={index} className=" text-center  cursor-pointer">
                <a
                  href={link.route}
                  className={`block text-white py-1 hover:border-b-primary-blue hover:border-b-2`}
                >
                  {link.title}
                </a>
              </li>
            );
          })}
        </ul>

        <div className="hidden lg:flex items-center gap-5">
          <a href="https://app.dev.grid78.com/login">
            <button className="text-white font-medium flex items-center gap-1 py-1 hover:border-b-primary-blue hover:border-b-2">
              <MdLogin />
              <span className="md:block hidden">Login</span>
            </button>
          </a>
          <a href="https://app.dev.grid78.com/register">
            <button className="border border-white font-medium text-white flex items-center gap-1 px-4 py-2 rounded-3xl hover:bg-[#7a8a8f]">
              <MdPersonAdd />
              <span className="md:block hidden">Free Trial</span>
            </button>
          </a>
        </div>

        {/* Links (mobile view) */}
        <ul
          className={`lg:hidden absolute top-0 left-0 w-[80%] h-[80vh] bg-gray-900 flex flex-col justify-center items-center transition-transform duration-300 ${
            navOpen ? "transform translate-x-0" : "transform -translate-x-full"
          }`}
        >
          <div className="lg:hidden absolute top-3 right-5">
            <button onClick={handleNavToggle}>
              <FaTimes size={26} className="text-white" />
            </button>
          </div>

          {MENU.map((link, index) => {
            const isActive = link.route === location.pathname;
            return (
              <a
                href={link.route}
                className="hover:text-gray-300 my-4 w-[80%]"
                key={index}
              >
                <li
                  className={` w-full text-center text-xl font-medium text-white py-2 bg-[#9ca3af50] hover:bg-gray-800 rounded-xl cursor-pointer ${
                    isActive ? "bg-[#b7b8b9] text-gray-500 " : "bg-[#9ca3af50]"
                  }`}
                >
                  {link.title}
                </li>
              </a>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
