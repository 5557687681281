import AccordionGroup from "../../../components/Accordion/AccordionGroup";
import Accordion from "../../../components/Accordion";

const FAQ = () => {
  return (
    <div className="bg-[#f8f8f8] pt-10 pb-20" id="faq">
      <div className="text-center lg:px-20 px-10">
        <span className="px-5 py-3 bg-transparent-blue rounded-[30px] font-semibold text-primary-blue">FAQ</span>
        <h2 className="md:text-3xl text-2xl text-[#333333] font-medium mt-6 mb-2 ">Frequently Asked Questions</h2>
        <p className="text-gray-500 font-medium mb-8">Explore our FAQ to learn more about how our platform can help you manage healthcare efficiently, securely, and seamlessly.</p>
      </div>
      <div className="xl:w-1/2 lg:w-3/4  lg:mx-auto mx-[2rem] bg-white md:px-10 px-[1rem] pt-10 pb-20 rounded-3xl shadow-[2px_2px_10px_4px_rgba(0,_0,_0,_0.1)]">
      <AccordionGroup>
        <Accordion title="What types of users can sign up on Grid 78?">
        <p>Grid 78 supports multiple user types, including patients, individual practitioners, clinics, and large healthcare organizations. Whether you’re solo or part of a team, there’s an option for you.</p>
        </Accordion>
        <Accordion title="How secure is patient data on Grid 78?">
          <p>We take security seriously. Grid 78 uses advanced encryption and secure access protocols to ensure that all patient data is protected and complies with healthcare data regulations.</p>
        </Accordion>
        <Accordion title="Can I add multiple practitioners to my account?">
        <p>Yes, Grid 78 allows you to invite multiple practitioners to your workspace. Each user can have customized access based on their role within your organization.</p>
        </Accordion>
        <Accordion title="Is Grid 78 compatible with mobile devices?">
        <p>Absolutely! Grid 78 is optimized for use on both mobile and desktop devices, so you can manage appointments, records, and more from anywhere.</p>
        </Accordion>
        <Accordion title="Does Grid 78 offer appointment reminders for patients?">
        <p>Yes, Grid 78 includes automated appointment reminders via SMS or email to help reduce no-shows and keep patients informed about their upcoming visits.</p>
        </Accordion>
        <Accordion title="Can I manage billing and invoicing through Grid 78?">
        <p> Absolutely. Grid 78 provides comprehensive billing and invoicing tools, allowing you to generate, track, and manage invoices easily from within the platform.</p>
        </Accordion>
        <Accordion title="How can Grid 78 help with team collaboration?">
        <p> Grid 78 enables seamless collaboration by allowing practitioners and staff to securely access shared records, schedules, and billing information, ensuring everyone is aligned on patient care.</p>
        </Accordion>
        <Accordion title="Is there a trial period for Grid 78?">
        <p>Yes, we offer a free trial so you can explore Grid 78's features and see how it fits your practice before committing to a paid plan.</p>
        </Accordion>
        
      </AccordionGroup>
      </div>
    </div>
  );
};

export default FAQ;
