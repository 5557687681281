import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import "./App.css";

function App() {
  return (
    <Router>
        <Routes>         
          <Route path="/" element={<Home />}></Route>  
          <Route path="/privacy" element={<Privacy />}></Route>  
          <Route path="/terms" element={<Terms />}></Route>  
        </Routes>
    </Router>
  );
}

export default App;
