const PrivacyPolicy = () => {
  return (
    <div className="py-6 md:mt-10 mt-5 mb-10 lg:px-20 px-10 flex flex-col md:space-y-8 space-y-6">
      <div>
        <h2 className="text-xl font-bold mb-2">1. Introduction</h2>
        <p className="md:text-xl">
          This Privacy Policy outlines how we collect, use, share, and protect
          your personal information when you use our services. By using our
          services, you agree to the collection and use of information in
          accordance with this policy.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">2. Information We Collect</h2>
        <p className="md:text-xl">
          We may collect various types of information, including but not limited
          to:
        </p>
        <ul className="md:text-xl list-disc ml-10 my-2">
          <li>
            <span className="font-medium">
              Personal Identifiable Information (PII):
            </span>{" "}
            Name, email address, contact details, etc.
          </li>
          <li>
            <span className="font-medium">Usage Data:</span> Information on how
            you interact with our services (e.g., IP address, browser type,
            pages visited, time spent).
          </li>
          <li>
            <span className="font-medium">Device Information:</span> Device
            type, operating system, unique device identifiers.
          </li>
        </ul>
        <p className="md:text-xl">
          We reserve the right to collect and store any data that is relevant to
          the operation of our services.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">3. Use of Information</h2>
        <ul className="md:text-xl list-disc ml-10 my-2">
          <li>Providing and maintaining our services.</li>
          <li>Improving and personalizing the user experience.</li>
          <li>
            Communicating with you, including sending updates, notices, and
            promotional material.
          </li>
          <li>
            Analyzing how users interact with our services for internal
            research.
          </li>
          <li>Complying with legal obligations or protecting our rights.</li>
        </ul>
        <p className="md:text-xl">
          We retain full discretion over the use of your information and may use
          it for any lawful purpose, at our sole discretion.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">
          4. Data Sharing and Disclosure
        </h2>
        <p className="md:text-xl">
          We may share or disclose your information under the following
          circumstances:
        </p>
        <ul className="md:text-xl list-disc ml-10 my-2">
          <li>
            <span className="font-medium">Service Providers:</span> We may share
            information with third-party service providers to help us operate
            our services.
          </li>
          <li>
            <span className="font-medium">Business Transfers:</span> If we are
            involved in a merger, acquisition, or asset sale, your information
            may be transferred.
          </li>
          <li>
            <span className="font-medium">Legal Requirements:</span> We may
            disclose information if required by law or in response to valid
            requests from public authorities.
          </li>
          <li>
            <span className="font-medium">At Our Discretion:</span> We reserve
            the right to share your information for any reason we deem necessary
            to protect our interests, comply with the law, or improve our
            services.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">5. Data Retention </h2>
        <p className="md:text-xl">
          We retain your personal information for as long as necessary to
          fulfill the purposes outlined in this policy. We may also retain data
          for longer periods if required by law, or for internal business
          purposes such as auditing, compliance, or fraud prevention.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">6. Data Security</h2>
        <p className="md:text-xl">
          We take the security of your data seriously and implement reasonable
          security measures to protect it from unauthorized access, use, or
          disclosure. However, we cannot guarantee the absolute security of any
          information you transmit to us or store on our servers, and you do so
          at your own risk.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">7. Your Rights </h2>
        <p className="md:text-xl">
          Depending on your location and applicable laws, you may have the right
          to:
        </p>
        <ul className="md:text-xl list-disc ml-10 my-2">
          <li>Access the personal information we hold about you.</li>
          <li>Request correction or deletion of your personal data.</li>
          <li>Opt-out of certain data processing activities.</li>
        </ul>
        <p className="md:text-xl">
          To exercise these rights, contact us at{" "}
          <a
            href="mailto:contact@grid78.com?subject=Grid%2078%20Trial"
            className="text-blue-600 underline"
          >
            contact@grid78.com
          </a>
          . However, we reserve the right to deny such requests if we believe it
          conflicts with our legitimate business interests or legal
          requirements.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2"> 8. Third-Party Links</h2>
        <p className="md:text-xl">
          Our services may contain links to third-party websites or services. We
          are not responsible for the privacy practices or content of these
          third-party sites, and accessing them is at your own risk.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">9. Children's Privacy</h2>
        <p className="md:text-xl">
          Our services are not intended for individuals under the age of 18. We
          do not knowingly collect personally identifiable information from
          children. If you believe we have collected data from a child, please
          contact us to have it removed.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">
          10. Changes to This Privacy Policy
        </h2>
        <p className="md:text-xl">
          We reserve the right to update this Privacy Policy at any time,
          without prior notice. Any changes will be posted on this page, and
          your continued use of our services after such changes will constitute
          your acknowledgment of the modified policy.
        </p>
      </div>
      <div>
        <h2 className="text-xl font-bold mb-2">11. Contact Information </h2>
        <p className="md:text-xl">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at{" "}
          <a
            href="mailto:contact@grid78.com?subject=Grid%2078%20Trial"
            className="text-blue-600 underline"
          >
            contact@grid78.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
