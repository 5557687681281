import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import TermsConditions from "./Terms&Condition";

const Terms = () => {
  return (
    <>
      <div className="bg-gray-900 ">
        <Navbar />
        <div className="text-white md:px-20 p-10">
          <div className="lg:w-3/4 mx-auto text-center">
            <h1 className="xl:text-[60px] md:text-[50px] mid:text-[30px] sm:text-[25px] font-bold mb-6">
              Terms <span className="text-primary-blue">&</span> Conditions
            </h1>
          </div>
        </div>
      </div>
      <TermsConditions />

      <Footer />
    </>
  );
};

export default Terms;
