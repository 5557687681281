const Accordion = ({ title, children, isOpen, onToggle }) => {
  return (
    <div className="accordion">
      <div
        className="accordion-header flex items-center justify-between cursor-pointer md:px-4 py-6 border-b"
        onClick={onToggle}
      >
        <span className="w-full">{title}</span>
        <div className="accordion-icon w-[20px] h-[20px] flex justify-center items-center text-center border border-blue-600 rounded-full">
          <p className="font-light text-green-400 mt-[-1px]">
            {isOpen ? "-" : "+"}
          </p>
        </div>
      </div>

      <div className={`accordion-content ${isOpen ? "open" : "closed"}`}>
        <div className="inner-content">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
