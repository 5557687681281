import PricingPlans from "../../../components/PricingPlans";

const Pricing = () => {
  return (
    <div className="bg-[#f8f8f8] py-10 " id="pricing">
      <div className="lg:px-20 px-10 text-center">
      <span className="px-5 py-3 bg-transparent-blue rounded-[30px] font-semibold text-primary-blue">Pricing</span>
      <h2 className="md:text-3xl text-2xl text-[#333333] font-medium mt-6 mb-2 ">Affordable Healthcare For Everyone</h2>
      <p className="text-gray-500 font-medium mb-8">Choose the plan that fits your healthcare needs, manage appointments, records, billing, and more with ease.</p>
      </div>
     
      <PricingPlans />
    </div>
  );
};

export default Pricing;
