import React from "react";
import one from "../../../assets/images/signature.jpg";
import two from "../../../assets/images/nurse.jpg";
import three from "../../../assets/images/record.jpg";
const HowItWorks = () => {
  return (
    <div className="lg:px-20 px-10 py-10 bg-[#f8f8f8] " id="how-it-works">
      <div className="card bg-white lg:px-20 md:px-10 px-5 py-[3rem] mb-20 shadow-[2px_2px_10px_4px_rgba(0,_0,_0,_0.1)] rounded-xl">
        <div>
          <h2 className="text-center my-4">
            <span className="px-5 py-3 bg-transparent-blue rounded-[30px] font-semibold text-primary-blue">
              How It Works
            </span>
          </h2>
          <h2 className="md:text-3xl text-2xl text-[#333333] font-medium mt-6 mb-4 capitalize text-center">
            Making healthcare management easy & intuitive
          </h2>
          <p className="text-gray-500 font-medium mb-8 text-center lg:w-[70%] md:w-[80%] mx-auto">
            In just a few simple steps, you can set up your account, bring your
            team onboard, and start managing patient care, scheduling, and
            billing — all in one platform!
          </p>
        </div>
        <div className="">
          <div className="flex md:justify-between md:flex-row flex-col items-center mb-16 md:gap-16 gap-8">
            <div className="md:w-[50%] h-[300px]">
              <img
                src={one}
                alt="one"
                className="w-full h-full object-cover mx-auto rounded-xl"
              />
            </div>
            <div className="md:w-[50%]">
              <h4 className="md:text-3xl text-2xl capitalize text-[#333333] font-medium md:mb-8 mb-6">
                Sign up
              </h4>
              <p className="text-gray-500 font-medium">
                Register as a healthcare practitioner, individual, or
                organization to create your account. Grid 78 offers tailored
                sign-up options to fit the needs of solo practitioners, small
                clinics, and larger healthcare organizations alike. 
                {/* Begin by choosing your user type, filling in your details, and setting up
                a secure account. Once registered, you’ll have access to our
                suite of tools designed for streamlined healthcare management. */}
              </p>
            </div>
          </div>

          <div className="flex md:justify-between md:flex-row flex-col-reverse items-center mb-16 md:gap-16 gap-8">
            <div className="md:w-[50%]">
              <h4 className="md:text-3xl text-2xl capitalize text-[#333333] font-medium md:mb-8 mb-6">
                Add practitioners
              </h4>
              <p className="text-gray-500 font-medium">
                 Grid 78
                allows you to add practitioners to your workspace with varying levels of access,
                ensuring that every team member has the tools and information
                they need, making it simple to stay connected and coordinated.
              </p>
            </div>
            <div className="md:w-[50%] h-[300px]">
              <img
                src={two}
                alt="two"
                className="w-full h-full object-cover mx-auto rounded-xl"
              />
            </div>
          </div>

          <div className="flex md:justify-between md:flex-row flex-col items-center mb-16 md:gap-16 gap-8">
            <div className="md:w-[50%] h-[300px]">
              <img
                src={three}
                alt="three"
                className="w-full h-full object-cover mx-auto rounded-xl"
              />
            </div>
            <div className="md:w-[50%]">
              <h4 className="md:text-3xl text-2xl capitalize text-[#333333] font-medium md:mb-8 mb-6">
                Manage records
              </h4>
              <p className="text-gray-500 font-medium">
                We provide a centralized dashboard where
                you can easily organize patient records, hospital records, book
                appointments, view upcoming appointments and manage payments - all
                from a single, user-friendly interface.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
