import React from "react";
import offer from "../../../assets/images/services.jpg";
import { FaCalendarAlt, FaBriefcaseMedical, FaFileInvoiceDollar, FaHospitalAlt } from "react-icons/fa";

const Services = () => {
  return (
    <div className=" lg:px-20 px-10 bg-[#f8f8f8] py-[5rem] flex justify-between gap-14 items-center" id="services">
      <div className="lg:block hidden w-[40%]">
        <img src={offer} alt="" className="w-full rounded-xl"/>
      </div>
      <div className="lg:w-[60%] py-6">
      <h2 className="lg:text-left text-center">
        <span className="px-5 py-3 bg-transparent-blue rounded-[30px] font-semibold text-primary-blue">Our Services</span>
        </h2>
        <h2 className="md:text-3xl text-2xl text-[#333333] font-medium mt-6 mb-2 lg:text-left text-center">Streamline Your Healthcare Management</h2>
        <p className="text-gray-500 font-medium mb-8 lg:text-left text-center">Simplify healthcare operations with an all-in-one platform to help you deliver quality care.</p>
        <div className="grid md:grid-cols-2 gap-10">
          <div>
            <p className="w-[50px] h-[50px] bg-transparent-blue rounded-xl flex items-center justify-center text-[25px] text-primary-blue"><FaCalendarAlt/></p>
            <h3 className="text-[#333333] font-semibold mt-4 mb-2 text-[18px]">Appointment Scheduling</h3>
            <p className="text-gray-500 font-medium" >Easily schedule and manage appointments with just a few clicks, helping you maximize your time and focus more on patient care.</p>
          </div>
          <div>
            <p className="w-[50px] h-[50px] bg-transparent-blue rounded-xl flex items-center justify-center text-[25px] text-primary-blue"><FaBriefcaseMedical/></p>
            <h3 className="text-[#333333] font-semibold mt-4 mb-2 text-[18px]">Patient Record Management</h3>
            <p className="text-gray-500 font-medium" >Keep patient information secure, organized, and easily accessible for managing patient data efficiently and securely.</p>
          </div>
          <div>
            <p className="w-[50px] h-[50px] bg-transparent-blue rounded-xl flex items-center justify-center text-[25px] text-primary-blue"><FaFileInvoiceDollar/></p>
            <h3 className="text-[#333333] font-semibold mt-4 mb-2 text-[18px]">Streamlined Invoicing and Billing</h3>
            <p className="text-gray-500 font-medium" >Simplify your billing process with our intuitive invoicing tools which helps you generate, track, and manage invoices.</p>
          </div>
          <div>
            <p className="w-[50px] h-[50px] bg-transparent-blue rounded-xl flex items-center justify-center text-[25px] text-primary-blue"><FaHospitalAlt/></p>
            <h3 className="text-[#333333] font-semibold mt-4 mb-2 text-[18px]">Team & Facility Management</h3>
            <p className="text-gray-500 font-medium" >Oversee your healthcare team and facility records in one place. From managing staff schedules to updating hospital data.</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Services;
