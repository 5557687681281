import { FaArrowRight } from "react-icons/fa6";

const Header = () => {
  return (
    <div className="lg:mx-20 mx-10 mb-[5rem]">
      <div className="xl:w-1/2 lg:w-4/5 md:w-4/5">
        <h1 className="lg:text-[50px] md:text-[40px] text-3xl text-white font-bold capitalize md:w-[80%] mb-4 leading-snug">
          Get better health care with{" "}
          <span className="text-primary-blue"></span>ease
        </h1>
        <p className="text-xl md:w-[80%] text-white font-medium">
          Providing better healthcare by working together and using smart tools
          to keep track of patient health.
        </p>
        <a href="https://app.dev.grid78.com/register">
          <button className="flex items-center border border-white font-medium text-white px-6 py-3 mt-6 rounded-[30px] hover:bg-[#7a8a8f]">
            Get Started
            <FaArrowRight className="ml-2" />
          </button>
        </a>
      </div>
    </div>
  );
};

export default Header;
